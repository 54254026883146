<template>
  <v-form v-model="validForm">
    <v-container>
      <v-card flat>
        <v-card-title>Edit Talent</v-card-title>
        <v-card-subtitle>Fill in the form below to edit {{ insertNewTalentData.name }}.</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.name"
                  :counter="50"
                  label="Name *"
                  required
              ></v-text-field>
            </v-col>

            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.country"
                  :counter="50"
                  label="Country *"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.address"
                  label="Address"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.city"
                  label="City *"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.zip"
                  label="Zip Code"
                  :counter="6"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.email"
                  label="Email"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
              <v-file-input
                  v-model="insertNewTalentData.file"
                  label="Picture"
                  accept="image/*"
                  chips
                  counter
                  show-size
              ></v-file-input>
            </v-col>
            <v-col
                cols="12"
                md="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.website"
                  label="Website"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                lg="4"
                sm="4"
                xs="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.rate"
                  label="Internal Rate ($) *"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                sm="4"
                xs="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.external_rate"
                  label="External Rate ($) *"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                sm="4"
                xs="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.profile.communication_skill"
                  label="Communication Level (1-10)"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="4"
                lg="4"
                sm="4"
                xs="4"
            >
              <v-autocomplete
                  v-model="insertNewTalentData.skill_id"
                  :items="skillList"
                  item-text="title"
                  item-value="id"
                  label="Primary Skill *"
                  hint="Select the primary skill of this talent."
                  persistent-hint
                  autocomplete="off"
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="4"
                lg="4"
                sm="4"
                xs="4"
            >
              <v-text-field
                  v-model="insertNewTalentData.skill_years"
                  label="Primary Skill Years of Experience (1-10)"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-switch
                  v-model="insertNewTalentData.featured_homepage"
                  label="Featured Homepage"
                  hint="Talent will be featured in the hero section."
                  persistent-hint
              ></v-switch>
            </v-col>
            <v-col cols="2">
              <v-switch
                  v-model="insertNewTalentData.featured_skill"
                  label="Featured Expertises"
                  hint="Talent will be featured on relevant landing pages."
                  persistent-hint
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>Profile</v-card-title>
        <v-card-subtitle>Fill in the following profile items for talent: {{insertNewTalentData.name}}</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.profile.title"
                  label="Profile Title *"
                  required
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.profile.short_bio"
                  label="Short Bio (displayed in the hover) *"
                  :counter="70"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
            >
              <v-textarea
                  v-model="insertNewTalentData.profile.summary"
                  label="Summary (displayed after title in CV) *"
                  rows="5"
                  :counter="1000"
                  required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-textarea
                  v-model="insertNewTalentData.profile.about_me"
                  label="About Me"
                  rows="5"
                  :counter="255"
              >

              </v-textarea>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="insertNewTalentData.profile.logic_rating"
                  label="Logic Test Rating"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                  v-model="insertNewTalentData.skills"
                  :items="skillList"
                  item-text="title"
                  item-value="id"
                  label="Skills"
                  multiple
                  chips
                  hint="Select all skills applicable to this talent"
                  persistent-hint
                  @input="fixedRealUltraOmegaSuperRealThisTimeForSureUpdateSkillsYearsOfExperience"
                  autocomplete="off"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                  v-model="insertNewTalentData.expertises"
                  :items="filteredExpertiseList"
                  item-text="title"
                  item-value="id"
                  label="Expertises"
                  multiple
                  chips
                  hint="Select all expertises applicable to this talent"
                  persistent-hint
                  autocomplete="off"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                  v-model="insertNewTalentData.featured_expertises"
                  :items="filteredSelectedExpertisesList"
                  item-text="title"
                  item-value="id"
                  label="Featured Expertises"
                  multiple
                  chips
                  hint="Select all expertises this talent should be featured on."
                  persistent-hint
                  autocomplete="off"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
            >
              <h4 v-if="insertNewTalentData.skillsYearsExperience.length > 0">Fill in years of experience below.</h4>
            </v-col>
            <v-col
                cols="12"
                md="2"
                v-for="(skill, i) in insertNewTalentData.skillsYearsExperience" :key="'skill-' + i"
            >
              <v-text-field
                  v-model="skill.value"
                  :label="`${skill.title}: Years of Experience`"
                  :counter="2"
                  required
              >

              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>Portfolio Items&nbsp;<v-btn small color="primary" @click="addNewPortfolio">+</v-btn></v-card-title>
        <v-card-subtitle>Add a portfolio item by clicking the "+" sign, click it again for multiple.</v-card-subtitle>
        <v-card-text>
          <v-row v-for="(portfolio, i) in insertNewTalentData.portfolioArray" :key="'portfolio-' + i">
            <v-col>
              <h4>Portfolio Item {{i+1}}</h4>
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-file-input
                      v-model="portfolio.file"
                      label="Portfolio Item"
                      accept="image/*"
                      chips
                      counter
                      show-size
                  ></v-file-input>
                  <v-text-field v-model="portfolio.description" label="Image Description"></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                  @click="$delete(insertNewTalentData.portfolioArray, i)"
                  color="red"
                  dark
              >
                Delete Portfolio Item {{i+1}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>Experience&nbsp;<v-btn small color="primary" @click="addNewExperience">+</v-btn></v-card-title>
        <v-card-subtitle>Add an experience by clicking the "+" sign, click it again for multiple.</v-card-subtitle>
        <v-card-text>
          <v-row v-for="(experience, i) in insertNewTalentData.experiences" :key="'exp-' + i">
            <v-col>
              <h4>Experience {{i+1}}</h4>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="experience.title"
                      label="Title * (ex. Full Stack Developer - React)"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="experience.date_from"
                      label="From Date *"
                      :counter="20"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="experience.date_to"
                      label="To Date *"
                      :counter="20"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="experience.employer"
                      label="Employer Name * (ex. Microsoft)"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-file-input
                      v-model="experience.file"
                      label="Logo"
                      accept="image/*"
                      chips
                      counter
                      show-size
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-autocomplete
                      v-model="experience.skills"
                      :items="skillList"
                      item-text="title"
                      item-value="id"
                      label="Skill Logos To Display"
                      multiple
                      chips
                      color="primary"
                      hint="Select all skills to be shown in this section"
                      persistent-hint
                      autocomplete="off"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <h4>Description Bullet Points For Experience {{i+1}} &nbsp;<v-btn color="secondary" small @click="experience.bulletpoints.push({title: null})">+</v-btn></h4>
                </v-col>
                <v-col
                    v-for="(bp, j) in experience.bulletpoints"
                    :key="'exp-bp' + j"
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="bp.title"
                      label="Description * (ex. I worked with React on a SaaS service)"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                  @click="$delete(insertNewTalentData.experiences, i)"
                  color="red"
                  dark
              >
                Delete Experience {{i+1}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>Education&nbsp;<v-btn small color="primary" @click="addNewEducation">+</v-btn></v-card-title>
        <v-card-subtitle>Add an education by clicking the "+" sign, click it again for multiple.</v-card-subtitle>
        <v-card-text>
          <v-row v-for="(education, i) in insertNewTalentData.education" :key="'edu-' + i">
            <v-col>
              <h4>Education {{i+1}}</h4>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="education.title"
                      label="Title * (ex. Bachelor's Degree Finance)"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="education.date_from"
                      label="From Date *"
                      :counter="20"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="education.date_to"
                      label="To Date *"
                      :counter="20"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="education.school"
                      label="School Name * (ex. Stockholm School of Economics)"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                  <h4>Description Bullet Points For Education {{i+1}} &nbsp;<v-btn color="secondary" small @click="education.bulletpoints.push({title: null})">+</v-btn></h4>
                </v-col>
                <v-col
                    v-for="(bp, j) in education.bulletpoints"
                    :key="'edu-bp' + j"
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="bp.title"
                      label="Description *"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn
                  @click="$delete(insertNewTalentData.education, i)"
                  color="red"
                  dark
              >
                Delete Education {{i+1}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat>
        <v-card-title>Strengths</v-card-title>
        <v-card-subtitle>Add bullet points for this talent's strengths</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col>
              <h4>Description Bullet Points For Strengths &nbsp;<v-btn color="secondary" small @click="insertNewTalentData.strengths.bulletpoints.push({title: null})">+</v-btn></h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                v-for="(bp, j) in insertNewTalentData.strengths.bulletpoints"
                :key="'str-bp' + j"
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="bp.title"
                  label="Description *"
                  required
              ></v-text-field>
              <v-btn
                  @click="$delete(insertNewTalentData.strengths.bulletpoints, j)"
                  color="red"
                  dark
                  x-small
              >
                Delete Strength {{j+1}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-switch v-model="insertNewTalentData.copy" label="Copy talent data"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-btn color="primary" style="margin-top: 5px; margin-right: 10px;" @click="putData">Submit</v-btn>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "TalentEdit",

  computed: {
    filteredExpertiseList() {
      let self = this
      return self.skillList.filter(t => self.insertNewTalentData.skills.includes(t.id));
    },

    filteredSelectedExpertisesList() {
      let self = this
      return self.skillList.filter(t => self.insertNewTalentData.expertises.includes(t.id));
    },
  },

  mounted() {
    this.getSkills()
    this.copyOfOriginalTalentData = JSON.parse(JSON.stringify(this.insertNewTalentData))
    this.getTalent()
  },

  data() {
    return {
      copyOfOriginalTalentData: {},
      skillList: [],
      insertNewTalentData: {
        name: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        rate: 20,
        external_rate: 27,
        email: "",
        communication_skill: 9,
        skill_years: 5,
        file: null,
        website: "",
        profile: {
          title: null,
          summary: null,
          short_bio: null,
          about_me: null,
          logic_rating: 9,
          communication_skill: 9,
        },
        experiences: [],
        education: [],
        strengths: {
          bulletpoints: [],
        },
        skills: [],
        expertises: [],
        skillsYearsExperience: [],
        skill_id: null,
        featured_homepage: false,
        featured_skill: false,
        featured_expertises: [],
        portfolioArray: [],
        copy: false,
      },
      validForm: false,
    }
  },

  methods: {
    reorganiseArrayOfData(responseArray)  {
      let self = this

      this.insertNewTalentData.name = responseArray.name
      this.insertNewTalentData.address = responseArray.address
      this.insertNewTalentData.zip = responseArray.zip
      this.insertNewTalentData.city = responseArray.city
      this.insertNewTalentData.country = responseArray.country
      this.insertNewTalentData.rate = responseArray.rate
      this.insertNewTalentData.external_rate = responseArray.external_rate
      this.insertNewTalentData.email = responseArray.email
      this.insertNewTalentData.skill_years = responseArray.skill_years
      this.insertNewTalentData.website = responseArray.website
      this.insertNewTalentData.skill_id = responseArray.skill_id
      this.insertNewTalentData.featured_homepage = responseArray.featured_homepage
      this.insertNewTalentData.featured_skill = responseArray.featured_skill

      this.insertNewTalentData.profile.title = responseArray.profile.title
      this.insertNewTalentData.profile.summary = responseArray.profile.summary
      this.insertNewTalentData.profile.short_bio = responseArray.profile.short_bio
      this.insertNewTalentData.profile.about_me = responseArray.profile.about_me
      this.insertNewTalentData.profile.logic_rating = responseArray.profile.logic_rating
      this.insertNewTalentData.profile.communication_skill = responseArray.profile.communication_skill

      // EXPERIENCES ARRAY
      responseArray.profile.experiences.forEach(t => {
        let experience = {
          title: t.title,
          date_from: t.date_from,
          date_to: t.date_to,
          employer: t.employer,
          skills: [],
          bulletpoints: [],
          id: t.id
        }

        t.skills.forEach(skill => {
          experience.skills.push(skill.id)
        })

        t.bullet_points.forEach(bulletPoint => {
          experience.bulletpoints.push({title: bulletPoint.title})
        })

        self.insertNewTalentData.experiences.push(experience)
      })

      // EDUCATION ARRAY
      responseArray.profile.education.forEach(t => {
        let education = {
          title: t.title,
          date_from: t.date_from,
          date_to: t.date_to,
          school: t.school,
          bulletpoints: []
        }

        t.bullet_points.forEach(bulletPoint => {
          education.bulletpoints.push({title: bulletPoint.title})
        })

        self.insertNewTalentData.education.push(education)
      })

      // STRENGTHS
      if (responseArray.profile.strengths.length > 0) {
        responseArray.profile.strengths[0].bullet_points.forEach(t => {
          self.insertNewTalentData.strengths.bulletpoints.push({title: t.title})
        })
      }

      // SKILLS & EXPERTISES
      responseArray.profile.skills.forEach(t => {
        self.insertNewTalentData.skills.push(t.id)

        let yearsOfExperience = {
          id: t.id,
          title: t.title,
          value: t.pivot.experience
        }
        self.insertNewTalentData.skillsYearsExperience.push(yearsOfExperience)
      })

      responseArray.profile.expertises.forEach(t => {
        self.insertNewTalentData.expertises.push(t.id)

        if (t.pivot.featured) {
          self.insertNewTalentData.featured_expertises.push(t.id)
        }
      })
    },

    /**
     * TODO: fix this stupid function at some point, I just can't solve it right now, entire thing is wrong from the beginning
     * @param skillArray
     */
    newUpdateSkillsYearsOfExperience(skillArray) {
      let self = this

      let list = self.skillList.filter(t => self.insertNewTalentData.skills.includes(t.id))

      console.log("WTF??????????")

      list.forEach(t => {
        skillArray.includes(t.id) ? console.log(`yep ${t.id} exists in ${skillArray}`) : console.log(`NOPE ${t.id} does NOT exist in ${skillArray}`)
      })

      // list.forEach(t => {
      //   skillArray.forEach(tt => {
      //     let result = self.insertNewTalentData.skillsYearsExperience.find(t => t.id === tt)
      //     if (result) {
      //       console.log(`t: ${t} was found comparing to tt: ${tt}`)
      //     } else {
      //       console.log(`t: ${t} was NOT found COMPARED to tt: ${tt}`)
      //     }
      //   })
      // })

      self.insertNewTalentData.skillsYearsExperience.forEach(t => {
        if (!skillArray.includes(t.id)) {
          self.insertNewTalentData.skillsYearsExperience.filter(tt => t.id !== tt.id)
        }
      })
    },

    updateSkillsYearsOfExperience(skill) {
      console.log(skill)
      let self = this
      self.insertNewTalentData.skillsYearsExperience = []
      let list = self.skillList.filter(t => self.insertNewTalentData.skills.includes(t.id))
      list.forEach(t => {
        self.insertNewTalentData.skillsYearsExperience.push({
          id: t.id,
          title: t.title,
          value: 3,
        })
      })
    },

    fixedRealUltraOmegaSuperRealThisTimeForSureUpdateSkillsYearsOfExperience(skill) {
      let self = this

      // Check if the skill_id still exists in the array arg, if not, filter it away
      self.insertNewTalentData.skillsYearsExperience = self.insertNewTalentData.skillsYearsExperience.filter(val => !!skill.includes(val.id))

      // just gonna loop through each
      skill.forEach(skillId => {
        // Let's get the skillObj for the current skill_id (t)
        let skillObj = self.skillList.filter(skill => skill.id === skillId)
        // Just assign it to the first element lol cba, does find() get it immediately? I guess it does I cba remembering
        skillObj = skillObj[0]
        // See if the current array is missing this skill_id, if it does, let's add a new obj to it
        self.insertNewTalentData.skillsYearsExperience.map(obj => obj.id).includes(skillId) ? console.log(`${skillId} already exists`) : self.insertNewTalentData.skillsYearsExperience.push({id: skillObj.id, title: skillObj.title, value: 3,})
      })
    },

    getSkills() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/skills`, this.$store.state.headers)
          .then(function (response) {
            self.skillList = response.data
          });
    },

    addNewExperience() {
      this.insertNewTalentData.experiences.push({
        title: "Developer",
        date_from: "2021-08-26",
        date_to: "2021-08-27",
        employer: null,
        file: null,
        skills: [],
        experience: 3,
        bulletpoints: [
          {
            title: "A bullet point."
          }
        ]
      })
    },

    addNewEducation() {
      this.insertNewTalentData.education.push({
        title: "Education",
        date_from: "2021-08-26",
        date_to: "2021-08-27",
        school: null,
        bulletpoints: [
          {
            title: "A bullet point."
          }
        ]
      })
    },

    addNewPortfolio() {
      this.insertNewTalentData.portfolioArray.push({})
    },

    getTalent() {
      let self = this
      axios.get(`${this.$store.state.apiUrl}/api/talent/${this.$route.params.id}`, this.$store.state.headers)
          .then(function (response) {
            self.reorganiseArrayOfData(response.data)
          })
    },

    putData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/talent/${this.$route.params.id}`, self.insertNewTalentData, this.$store.state.headers)
            .then(function (response) {
              alert("Data saved successfully! If you uploaded files you will soon get a file success (or error) as well")
              if (self.insertNewTalentData.copy === false)
                self.postUpload(response)
            })
            .catch(function (response) {
              alert(response.data)
            })
      });
    },

    postUpload(responseObj) {
      let self = this
      const formData = new FormData();

      formData.append("talentId", responseObj.data ? responseObj.data.id : null)

      if (self.insertNewTalentData.file) {
        formData.append("talentImage", self.insertNewTalentData.file, self.insertNewTalentData.file ? self.insertNewTalentData.file.name : "undefined");
      }

      self.insertNewTalentData.experiences.forEach(t => {
        formData.append("experienceLogoArray[]", t.file)
      })

      self.insertNewTalentData.portfolioArray.forEach(t => {
        formData.append("portfolioArray[]", t.file)
        formData.append("portfolioDescriptionArray[]", t.description)
      })

      responseObj.data.profile.experiences.forEach(t => {
        formData.append("experienceIdArray[]", t.id)
      })

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.post(`${this.$store.state.apiUrl}/api/talent/files`, formData, { headers: {
            'content-type': 'multipart/form-data'
          }})
            .then(function (response) {
              console.log(response.data);
              alert("Files success")
              //self.$router.push("/talent");
            })
            .catch(function (response) {
              alert(response.response.data.errors)
            })
      });
    }
  }
}
</script>

<style scoped>
.v-card {
  margin-top: 10px;
}
</style>
